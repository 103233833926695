import React from 'react';

const Questions = () => {
    return (
        <>
            <div id="contactsSection" className="questions-container">
                <h2>Остались вопросы?<br></br>
                    Свяжитесь с нами!</h2>
                <a target="_blank" href="mailto:findcareer@mail.ru">Связаться по электронной почте</a>
            </div>
        </>
);
}

export default Questions;