import React from 'react';

import stars from '../assets/stars.svg';
import IrinaV from '../assets/IrinaV.png';
import DmitriyI from '../assets/DmitriyI.png';
import MaximA from '../assets/MaximA.png';
import AlenaK from '../assets/AlenaK.png';
import IlyaI from '../assets/IlyaI.png';

const Welcome = () => {
    const scrollToSection = (id) => {
        document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <>
            <div className="welcome-container">
                <div className="welcome-container__info">
                    <div className="info__text">
                        <h1>Работы мечты — здесь</h1>
                        <p>Ищете работу? Наши инструменты помогут вам <strong>быстро</strong> найти<br></br>
                            идеальную вакансию и связаться с работодателями</p>
                    </div>
                    <button onClick={() => scrollToSection('vacanciesSection')}>Перейти к поиску работы</button>
                </div>
                <div className="welcome-container__feedback">
                    <div className="feedback_block">
                        <div className="feedback_block__user">
                            <img className="user__img" src={IrinaV}/>
                            <p className="user__name">Ирина В.</p>
                            <img className="user__stars" src={stars}/>
                        </div>
                        <p className="feedback_block__desc">Сайт помог мне найти работу мечты всего за неделю!
                            Рекомендую всем!</p>
                    </div>
                    <div className="feedback_block">
                        <div className="feedback_block__user">
                            <img className="user__img" src={DmitriyI}/>
                            <p className="user__name">Дмитрий И.</p>
                            <img className="user__stars" src={stars}/>
                        </div>
                        <p className="feedback_block__desc">Очень удобный интерфейс и множество актуальных вакансий. Спасибо!</p>
                    </div>
                    <div className="feedback_block">
                        <div className="feedback_block__user">
                            <img className="user__img" src={MaximA}/>
                            <p className="user__name">Максим А.</p>
                            <img className="user__stars" src={stars}/>
                        </div>
                        <p className="feedback_block__desc">Самый удобный сайт для поиска работы. Рекомендую друзьям и коллегам!</p>
                    </div>
                    <div className="feedback_block">
                        <div className="feedback_block__user">
                            <img className="user__img" src={AlenaK}/>
                            <p className="user__name">Алёна К.</p>
                            <img className="user__stars" src={stars}/>
                        </div>
                        <p className="feedback_block__desc">Искала работу несколько месяцев, а с вашим сайтом нашел за пару дней.</p>
                    </div>
                    <div className="feedback_block">
                        <div className="feedback_block__user">
                            <img className="user__img" src={IlyaI}/>
                            <p className="user__name">Илья И.</p>
                            <img className="user__stars" src={stars}/>
                        </div>
                        <p className="feedback_block__desc">Сайт реально работает! Уже через неделю вышел на новую работу.</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Welcome;