import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Header from './components/Header';
import Welcome from "./components/Welcome";
import AboutUs from "./components/AboutUs";
import Feedback from "./components/Feedback";
import Vacancies from "./components/Vacancies";
import Questions from "./components/Questions";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Header />
    <Welcome />
      <Vacancies />
      <AboutUs />
      <Feedback />
      <Questions/>
  </React.StrictMode>
);
