import React from 'react';
import moneyIcon from '../assets/money-icon.svg'
import Sbermarket from '../assets/companies/sbermarket.png'
import Yandexlavka from '../assets/companies/yandexlavka.png'
import Yandexeda from '../assets/companies/yandexeda.png'
import BurgerKing from '../assets/companies/burgerking.png'
import Yandex from '../assets/companies/yandex.png'
import Vkusvill from '../assets/companies/vkusvill.png'
import Cdek from '../assets/companies/cdek.png'
import OzonFresh from '../assets/companies/ozonfresh.jpg'
import Samokat from '../assets/companies/samokat.png'
import Voxys from '../assets/companies/voxys.png'
import AlfaBank from '../assets/companies/alfabank.png'
import TBank from '../assets/companies/tbank.jpg'
import Beeline from '../assets/companies/beeline1.jpg'
import X5 from '../assets/companies/x5.png'
import YandexMarket from '../assets/companies/yandexmarket.jpg'


const Vacancies = () => {
    return (
        <div id="vacanciesSection" className="vacancies-section">
            <h3 className="vacancies-section__title">Выберите подходящую работу</h3>
            <div className="vacancies-section__container">


                <div className="vacancie-card">
                    <div className="card-company">
                        <img className="card-company__logo" src={Yandex}/>
                        <div className="card-company__info">
                            <p className="company-title">Менеджер по продажам</p>
                            <p className="company-format">(удаленно)</p>
                        </div>
                    </div>
                    <div className="card-money">
                        <img src={moneyIcon}/>
                        <p>от 48 000₽ в месяц</p>
                    </div>
                    <p className="offer-title"></p>
                    <p className="offer-desc">Работа в крупной IT-компании.
                        Возможность роста и развития, компенсация затрат за интернет.
                        Общаться с клиентами можно там, где вам удобно, — хоть на даче, если там хорошая связь.
                        Гибкий график — 5/2 с плавающими выходными и фиксированными сменами (4 или 8 часов в день).
                        Оплачиваемое обучение перед стартом, тоже удалённое.</p>
                    <a target="_blank"
                       href="https://trk.ppdu.ru/click?uid=201035&pid=1&oid=0c499606-3cae-4d97-810d-5cd78b1a8455&erid=2SDnjcGdzVw"
                       className="offer-button">Откликнуться на вакансию</a>
                </div>

                <div className="vacancie-card">
                    <div className="card-company">
                        <img className="card-company__logo" src={Voxys}/>
                        <div className="card-company__info">
                            <p className="company-title">Voxys</p>
                            <p className="company-format">(удалённо)</p>
                        </div>
                    </div>
                    <div className="card-money">
                        <img src={moneyIcon}/>
                        <p>до 45 000₽ в месяц</p>
                    </div>
                    <p className="offer-title">Специалист центра коммуникаций</p>
                    <p className="offer-desc">Гибкий график, подработка от 4 часов, возможность частичной занятости и
                        совмещения с учебой.
                        Дружная команда, широкие возможности для профессионального и личностного роста.
                        Отличный старт для успешной карьеры.</p>
                    <a target="_blank"
                       href="https://streamreg.ru/go/66e421497c7d309923"
                       className="offer-button">Откликнуться на вакансию</a>
                </div>

                <div className="vacancie-card">
                    <div className="card-company">
                        <img className="card-company__logo" src={TBank}/>
                        <div className="card-company__info">
                            <p className="company-title">Т-Банк</p>
                            <p className="company-format">(удалённо)</p>
                        </div>
                    </div>
                    <div className="card-money">
                        <img src={moneyIcon}/>
                        <p>по результатам собеседования</p>
                    </div>
                    <p className="offer-title">Оператор дистанционной обработки данных</p>
                    <p className="offer-desc">Достойная оплата. Даже без опыта.
                        Работа с комфортом из дома или офиса.
                        Современная компания. Без бюрократии.</p>
                    <a target="_blank"
                       href="https://streamreg.ru/go/66e4202e0dd5909923"
                       className="offer-button">Откликнуться на вакансию</a>
                </div>

                <div className="vacancie-card">
                    <div className="card-company">
                        <img className="card-company__logo" src={YandexMarket}/>
                        <div className="card-company__info">
                            <p className="company-title">Оператор колл-центра на входящую линию</p>
                            <p className="company-format">(удалённо)</p>
                        </div>
                    </div>
                    <div className="card-money">
                        <img src={moneyIcon}/>
                        <p>до 45 000₽ в месяц</p>
                    </div>
                    <p className="offer-title"></p>
                    <p className="offer-desc">Удаленная работа: из дома на вашем компьютере или ноутбуке.
                        Официальное трудоустройство по ТК РФ с первого дня обучения. Гибкий график: 5/2 по 8 часов или
                        2/2 по 11 часов (на выбор).
                        Карьерный рост: возможность построить карьеру в Яндексе, даже если у вас нет опыта работы.</p>
                    <a target="_blank"
                       href="https://my.saleads.pro/s/t7t0e?erid=2VtzqvXxqFu"
                       className="offer-button">Откликнуться на вакансию</a>
                </div>

                <div className="vacancie-card">
                    <div className="card-company">
                        <img className="card-company__logo" src={AlfaBank}/>
                        <div className="card-company__info">
                            <p className="company-title">Специалист по дистанционной работе с клиентами</p>
                            <p className="company-format">(удалённо)</p>
                        </div>
                    </div>
                    <div className="card-money">
                        <img src={moneyIcon}/>
                        <p>по результатам собеседования</p>
                    </div>
                    <p className="offer-title"></p>
                    <p className="offer-desc">Удаленная работа: вы сможете работать там, где вам удобно.Возможности
                        карьерного и профессионального роста.
                        Комфортная адаптация: вам будет помогать наставник, который расскажет, как лучше поступать в той
                        или иной рабочей ситуации.
                        Достойная зарплата и условия труда, современные офисы и льготы.</p>
                    <a target="_blank"
                       href="https://my.saleads.pro/s/fxscn?erid=2VtzqxgvMHq"
                       className="offer-button">Откликнуться на вакансию</a>
                </div>

                <div className="vacancie-card">
                    <div className="card-company">
                        <img className="card-company__logo" src={Sbermarket}/>
                        <div className="card-company__info">
                            <p className="company-title">Водитель-курьер/пеший курьер</p>
                            <p className="company-format"></p>
                        </div>
                    </div>
                    <div className="card-money">
                        <img src={moneyIcon}/>
                        <p>до 162 000₽ в месяц</p>
                    </div>
                    <p className="offer-title"></p>
                    <p className="offer-desc">Возможность выбора района доставки, свободный график, льготное
                        обслуживание транспорта.
                        Официальное трудоустройство с первого дня.</p>
                    <a target="_blank"
                       href="https://trk.ppdu.ru/click?uid=201035&pid=1&oid=3512e9c0-a7eb-48ba-87df-b919f5c182cf&erid=2SDnjeL6Zwp"
                       className="offer-button">Откликнуться на вакансию</a>
                </div>

                <div className="vacancie-card">
                    <div className="card-company">
                        <img className="card-company__logo" src={Yandexlavka}/>
                        <div className="card-company__info">
                            <p className="company-title">Партнер сервиса Яндекс.Лавка</p>
                            <p className="company-format"></p>
                        </div>
                    </div>
                    <div className="card-money">
                        <img src={moneyIcon}/>
                        <p>от 80 000₽ в месяц</p>
                    </div>
                    <p className="offer-title">Кладовщик-комплектовщик</p>
                    <p className="offer-desc">Еженедельные выплаты. Подбор склада наиболее близкого к вашему дому.
                        Возможность совмещения с работой/учебой.</p>
                    <a target="_blank"
                       href="https://trk.ppdu.ru/click?uid=201035&pid=1&oid=a3acd937-2465-4f56-9eac-9dfd8d96edf6&erid=2SDnjdu4gFa"
                       className="offer-button">Откликнуться на вакансию</a>
                </div>

                <div className="vacancie-card">
                    <div className="card-company">
                        <img className="card-company__logo" src={Yandexeda}/>
                        <div className="card-company__info">
                            <p className="company-title">Курьер (Пеший + Вело + Авто)</p>
                            <p className="company-format"></p>
                        </div>
                    </div>
                    <div className="card-money">
                        <img src={moneyIcon}/>
                        <p>до 3 500₽ в день</p>
                    </div>
                    <p className="offer-title"></p>
                    <p className="offer-desc">Комфортное расписание и график работы.
                        Большое количество поступающих заказов (заказы поступают 24/7 круглосуточно).
                        Гарантии и бонусы. Возможность совмещения с учебой или с работой.</p>
                    <a target="_blank"
                       href="https://trk.ppdu.ru/click?uid=201035&pid=1&oid=31ba9c13-4847-41f9-bc4a-1f4a1fbced43&erid=Kra23uVC3"
                       className="offer-button">Откликнуться на вакансию</a>
                </div>

                <div className="vacancie-card">
                    <div className="card-company">
                        <img className="card-company__logo" src={BurgerKing}/>
                        <div className="card-company__info">
                            <p className="company-title">Повар-кассир</p>
                            <p className="company-format"></p>
                        </div>
                    </div>
                    <div className="card-money">
                        <img src={moneyIcon}/>
                        <p>до 96 000₽ в месяц</p>
                    </div>
                    <p className="offer-title"></p>
                    <p className="offer-desc">График работы по выбору, возможна свободная подработка.
                        Работа рядом с домом.
                        Обучение и карьерный рост.</p>
                    <a target="_blank"
                       href="https://trk.ppdu.ru/click?uid=201035&pid=1&oid=131ca6e0-b3bc-47f8-8367-180f65044009&erid=2SDnjdu6ZqS"
                       className="offer-button">Откликнуться на вакансию</a>
                </div>

                <div className="vacancie-card">
                    <div className="card-company">
                        <img className="card-company__logo" src={BurgerKing}/>
                        <div className="card-company__info">
                            <p className="company-title">Курьер (пеший/авто)</p>
                            <p className="company-format"></p>
                        </div>
                    </div>
                    <div className="card-money">
                        <img src={moneyIcon}/>
                        <p>от 70 000₽ в месяц</p>
                    </div>
                    <p className="offer-title"></p>
                    <p className="offer-desc">Еженедельные выплаты, безналичные чаевые.
                        Бесплатное питание, отсутствие штрафов.
                        Сменный график работы на выбор, возможность совмещения с другой работой или учёбой.</p>
                    <a target="_blank"
                       href="https://trk.ppdu.ru/click?uid=201035&pid=1&oid=131ca6e0-b3bc-47f8-8367-180f65044009&erid=2SDnjdu6ZqS"
                       className="offer-button">Откликнуться на вакансию</a>
                </div>

                <div className="vacancie-card">
                    <div className="card-company">
                        <img className="card-company__logo" src={Vkusvill}/>
                        <div className="card-company__info">
                            <p className="company-title">Мобильный продавец</p>
                            <p className="company-format"></p>
                        </div>
                    </div>
                    <div className="card-money">
                        <img src={moneyIcon}/>
                        <p>от 40 000₽ в месяц</p>
                    </div>
                    <p className="offer-title"></p>
                    <p className="offer-desc">Удобный график, оплачиваемая стажировка.
                        Скидки на продукцию компании, компенсация питания.
                        Отсутствие штрафов, подарки сотрудникам на праздники.</p>
                    <a target="_blank"
                       href="https://trk.ppdu.ru/click?uid=201035&pid=1&oid=76e3c7a9-262e-4ad7-813c-1d3954dd7cfd&erid=Kra249bGK"
                       className="offer-button">Откликнуться на вакансию</a>
                </div>

                <div className="vacancie-card">
                    <div className="card-company">
                        <img className="card-company__logo" src={Cdek}/>
                        <div className="card-company__info">
                            <p className="company-title">СДЭК</p>
                            <p className="company-format"></p>
                        </div>
                    </div>
                    <div className="card-money">
                        <img src={moneyIcon}/>
                        <p>до 115 000₽ в месяц</p>
                    </div>
                    <p className="offer-title">Кладовщик-комплектовщик (Москва/МО)</p>
                    <p className="offer-desc">Полный социальный пакет, корпоративный транспорт.
                        Трудоустройство по трудовой книжке. График 4/4 .</p>
                    <a target="_blank"
                       href="https://streamreg.ru/go/66e4222b2476909923"
                       className="offer-button">Откликнуться на вакансию</a>
                </div>

                <div className="vacancie-card">
                    <div className="card-company">
                        <img className="card-company__logo" src={OzonFresh}/>
                        <div className="card-company__info">
                            <p className="company-title">Ozon Fresh</p>
                            <p className="company-format"></p>
                        </div>
                    </div>
                    <div className="card-money">
                        <img src={moneyIcon}/>
                        <p>от 70 000₽ в месяц</p>
                    </div>
                    <p className="offer-title">Водитель-курьер на личном автомобиле</p>
                    <p className="offer-desc">Еженедельные выплаты. График работы: 3/3 (дневные смены).
                        Работа в закрепленном за вами районе. Тарифы предусматривают все затраты на эксплуатацию
                        автомобиля
                        .</p>
                    <a target="_blank"
                       href="https://streamreg.ru/go/66e42ec4003d809923"
                       className="offer-button">Откликнуться на вакансию</a>
                </div>


                <div className="vacancie-card">
                    <div className="card-company">
                        <img className="card-company__logo" src={AlfaBank}/>
                        <div className="card-company__info">
                            <p className="company-title">Менеджер отделения банка по работе с клиентами</p>
                            <p className="company-format"></p>
                        </div>
                    </div>
                    <div className="card-money">
                        <img src={moneyIcon}/>
                        <p>по результатам собеседования</p>
                    </div>
                    <p className="offer-title">Менеджер отделения банка по работе с клиентами</p>
                    <p className="offer-desc">График работы: 5/2. Возможности для карьерного роста.
                        ДМС, стоматология, страхование жизни и страхование выезжающих за рубеж после прохождения
                        испытательного срока.
                        Достойная зарплата и условия труда, современные офисы и льготы.</p>
                    <a target="_blank"
                       href="https://my.saleads.pro/s/pjpe3?erid=2Vtzqwo1QDd"
                       className="offer-button">Откликнуться на вакансию</a>
                </div>

                <div className="vacancie-card">
                    <div className="card-company">
                        <img className="card-company__logo" src={AlfaBank}/>
                        <div className="card-company__info">
                            <p className="company-title">Специалист по доставке пластиковых карт</p>
                            <p className="company-format"></p>
                        </div>
                    </div>
                    <div className="card-money">
                        <img src={moneyIcon}/>
                        <p>по результатам собеседования</p>
                    </div>
                    <p className="offer-title"></p>
                    <p className="offer-desc">Гибкий график работы. Возможности для карьерного роста.
                        Комфортный дресс-код. Оформление по ТК РФ. Бесплатное обучение: тренинги,
                        вебинары, доступ к корпоративным библиотекам и бизнес-изданиям.
                        Достойная зарплата и условия труда, современные офисы и льготы.</p>
                    <a target="_blank"
                       href="https://my.saleads.pro/s/o2oxl?erid=2Vtzqxjtd43"
                       className="offer-button">Откликнуться на вакансию</a>
                </div>

                <div className="vacancie-card">
                    <div className="card-company">
                        <img className="card-company__logo" src={Samokat}/>
                        <div className="card-company__info">
                            <p className="company-title">Партнер компании Самокат</p>
                            <p className="company-format"></p>
                        </div>
                    </div>
                    <div className="card-money">
                        <img src={moneyIcon}/>
                        <p>до 148 800 ₽ в месяц (в зависимости от города)</p>
                    </div>
                    <p className="offer-title">Пеший/вело курьер партнерской доставки</p>
                    <p className="offer-desc">Оказание услуг по договору Самозанятости и ГПХ.
                        Гарантированный доход ЕЖЕНЕДЕЛЬНО.
                        Совмещай с учебой или с работой. Выбирай удобное время для доставок.</p>
                    <a target="_blank"
                       href="https://streamreg.ru/go/66e421da1d70509923"
                       className="offer-button">Откликнуться на вакансию</a>
                </div>


                <div className="vacancie-card">
                    <div className="card-company">
                        <img className="card-company__logo" src={Yandex}/>
                        <div className="card-company__info">
                            <p className="company-title">Специалист по сбору геоданных</p>
                            <p className="company-format"></p>
                        </div>
                    </div>
                    <div className="card-money">
                        <img src={moneyIcon}/>
                        <p>от 70 000₽ в месяц</p>
                    </div>
                    <p className="offer-title"></p>
                    <p className="offer-desc">Работа в интервале с 10:00 до 18:00 по будням от 25 часов в неделю.
                        Сдельная оплата зависит от количества выполненных задач, чем больше вы их делаете, тем выше
                        вознаграждение.</p>
                    <a target="_blank"
                       href="https://trk.ppdu.ru/click?uid=201035&pid=1&oid=2eaf098d-75eb-4996-b861-f6320119fd44&erid=2SDnjeiojYu"
                       className="offer-button">Откликнуться на вакансию</a>
                </div>

                <div className="vacancie-card">
                    <div className="card-company">
                        <img className="card-company__logo" src={X5}/>
                        <div className="card-company__info">
                            <p className="company-title">Пятёрочка</p>
                            <p className="company-format"></p>
                        </div>
                    </div>
                    <div className="card-money">
                        <img src={moneyIcon}/>
                        <p>до 120 000₽ в месяц</p>
                    </div>
                    <p className="offer-title">Кладовщик-комплектовщик</p>
                    <p className="offer-desc">Гибкий график работы: 2/2, дневные и ночные смены чередуются.
                        Широкая сеть магазинов с возможностью работы рядом с домом. Социальные гарантии, оплата отпусков
                        и больничных листов.</p>
                    <a target="_blank"
                       href="https://streamreg.ru/go/66e42f98d64ac09923"
                       className="offer-button">Откликнуться на вакансию</a>
                </div>

                <div className="vacancie-card">
                    <div className="card-company">
                        <img className="card-company__logo" src={Beeline}/>
                        <div className="card-company__info">
                            <p className="company-title">Билайн</p>
                            <p className="company-format"></p>
                        </div>
                    </div>
                    <div className="card-money">
                        <img src={moneyIcon}/>
                        <p>от 32 000₽ в месяц</p>
                    </div>
                    <p className="offer-title">Оператор колл-центра (ПОДДЕРЖКА)</p>
                    <p className="offer-desc">Оплата обучения с первого дня. Официальное оформление с первого дня по ТК.
                        Подключение ДМС, включая стоматологию, через 3 месяца после начала работы.
                        Оплата сотовой связи тебе и твоей семье.</p>
                    <a target="_blank"
                       href="https://streamreg.ru/go/66e42f5cdd3c709923"
                       className="offer-button">Откликнуться на вакансию</a>
                </div>


            </div>
        </div>
    );
}

export default Vacancies