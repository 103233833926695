import React, {useEffect, useState} from 'react';

import AboutImageOne from '../assets/about-image-one.png';
import AboutImageTwo from '../assets/about-image-two.png';
import AboutImageThree from '../assets/about-image-three.png';

const AboutUs = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            <div id="aboutUsSection" className="about-container">
                <h2>Платформа для поиска работы</h2>
                {!isMobile ? (
                <div className="about-container__blocks">
                    <div className="blocks__about-block">
                        <img className="about-block__img" src={AboutImageOne}/>
                        <div className="about-block__main-info">
                            <p className="main-info__title">Поиск работы за минуты</p>
                            <p className="main-info__desc">Наш продукт помогает Вам найти работу за считанные минуты. Вы
                                можете сосредоточиться на своих навыках и не беспокоиться о технических деталях. </p>
                        </div>
                    </div>
                    <div className="blocks__about-block">
                        <div className="about-block__main-info">
                            <p className="main-info__title">Получите отзывы работодателей</p>
                            <p className="main-info__desc">Вы можете получить отзывы от работодателей и убедиться, что вы на правильном пути. С нашим продуктом вы получите уверенность в своих силах.</p>
                        </div>
                        <img className="about-block__img second-img" src={AboutImageTwo}/>
                    </div>
                    <div className="blocks__about-block">
                        <img className="about-block__img" src={AboutImageThree}/>
                        <div className="about-block__main-info">
                            <p className="main-info__title">Уверенность в своих силах</p>
                            <p className="main-info__desc">FindCareer снимает боль с процесса поиска работы. Свяжитесь с работодателями через электронную почту, опросы или интервью.</p>
                        </div>
                    </div>
                </div>
                ) : (
                    <div className="about-container__blocks">
                        <div className="blocks__about-block">
                            <img className="about-block__img" src={AboutImageOne}/>
                            <div className="about-block__main-info">
                                <p className="main-info__title">Поиск работы за минуты</p>
                                <p className="main-info__desc">Наш продукт помогает Вам найти работу за считанные
                                    минуты. Вы
                                    можете сосредоточиться на своих навыках и не беспокоиться о технических
                                    деталях. </p>
                            </div>
                        </div>
                        <div className="blocks__about-block">
                            <img className="about-block__img second-img" src={AboutImageTwo}/>
                            <div className="about-block__main-info">
                                <p className="main-info__title">Получите отзывы работодателей</p>
                                <p className="main-info__desc">Вы можете получить отзывы от работодателей и убедиться,
                                    что вы на правильном пути. С нашим продуктом вы получите уверенность в своих
                                    силах.</p>
                            </div>
                        </div>
                        <div className="blocks__about-block">
                            <img className="about-block__img" src={AboutImageThree}/>
                            <div className="about-block__main-info">
                                <p className="main-info__title">Уверенность в своих силах</p>
                                <p className="main-info__desc">FindCareer снимает боль с процесса поиска работы.
                                    Свяжитесь с работодателями через электронную почту, опросы или интервью.</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default AboutUs;