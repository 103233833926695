import React from 'react';

import logotypeSvg from '../assets/logotype.svg';

const Header = () => {
    const scrollToSection = (id) => {
        document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <>
            <header>
                <img src={logotypeSvg} alt="FindCareer" />
                <div className="header__btn-group">
                    <button onClick={() => scrollToSection('vacanciesSection')}>Перейти к вакансиям</button>
                    <button onClick={() => scrollToSection('aboutUsSection')}>О нас</button>
                    <button onClick={() => scrollToSection('contactsSection')}>Контакты</button>
                </div>
            </header>
        </>
    );
}

export default Header;