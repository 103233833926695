import React from 'react';


import OlegV from '../assets/OlegV.png';

const Feedback = () => {
    return (
        <>
            <div className="feedback-container">
                <h3 className="feedback-container__feedback">"Это изменило мой подход к поиску работы."</h3>
                <div className="feedback-container__user-feedback">
                    <img src={OlegV}/>
                    <p>Олег В.</p>
                </div>
            </div>
        </>
    );
}

export default Feedback;